export const colorSetReplace = (sourceData, defaultColors, replaceColors) => {
  let output = sourceData

  defaultColors.forEach((color, index) => {
    if (!replaceColors[index]) {
      return
    }

    output = output.replaceAll(color, replaceColors[index])
  })

  return output
}