import React, { ReactElement, useCallback } from 'react'
import { AssetGroupId } from '../../../states/asset-groups.state'
import { useRecoilValue } from 'recoil'
import { itemAssetsIdsState } from '../../../states/item-assets-ids.state'

interface Props {
  readonly isActive: boolean
  readonly label: string
  readonly groupId: AssetGroupId
  readonly onPress: (groupId: string) => void
}

const EditorAssetGalleryItem = (props: Props): ReactElement => {
  const { isActive, onPress, groupId, label } = props

  const assetsIds = useRecoilValue(itemAssetsIdsState(groupId))

  const onPressed = useCallback(() => {
    onPress(groupId)
    window.localStorage.setItem('last_group', groupId)
  }, [groupId, onPress])

  if (assetsIds.length === 0) {
    return null
  }

  return (
    <li onClick={onPressed} key={groupId} className={isActive ? 'active' : undefined}>
      {label}
    </li>
  )
}

export default React.memo(EditorAssetGalleryItem)