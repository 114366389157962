export const localStorageRecoilEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }
    onSet((newValue) => {
      localStorage.setItem(key, JSON.stringify(newValue))
    })
  }