import React, { ReactElement } from 'react'
import EditorLayer from './editor-layer'
// CSS
import './editor.scss'
import { useRecoilValue } from 'recoil'
import { assetGroupsState } from '../../states/asset-groups.state'
import { editedItemState } from '../../states/edited-item.state'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { zeroPad } from '../../utils/numZeroPadding'
import {isGeneratingState} from "../../states/is-generating-state";
import CenterComponent from "../center-component/center-component";
import {SpinnerCircularSplit} from "spinners-react";

const Editor = (): ReactElement => {
  /**
   * Fro where there will be distribution of edited item.
   */
  const assetGroups = useRecoilValue(assetGroupsState)
  const editedData = useRecoilValue(editedItemState)
  const isGenerating = useRecoilValue(isGeneratingState)

  // TODO: DNA IS UNIQUE
  const isUnique = true

  if(isGenerating){
    return <CenterComponent>
      <SpinnerCircularSplit color={'white'} secondaryColor={'#d0a5b2'} size={300} thickness={5} />
    </CenterComponent>
  }


  return (
    <div>
      <h2>
        {editedData.name} {zeroPad(editedData.order, 5)}
      </h2>
      {editedData.checksum && (
        <div style={{ paddingBottom: '1rem' }}>
          DNA:{' '}
          <CopyToClipboard text={"Lol..."}>
            <strong
              title="Copy to clipboard"
              className="pressable"
              style={{
                background: isUnique ? '#2ec155' : 'deeppink',
                color: 'white',
                padding: '0.15rem 0.25rem',
                borderRadius: '4px',
              }}>
              {editedData.checksum}
            </strong>
          </CopyToClipboard>
        </div>
      )}
      <div className="editor">
        {assetGroups.map((item) => {
          return <EditorLayer key={item.name} group={item.name} />
        })}
      </div>
    </div>
  )
}

export default React.memo(Editor)