import { atom } from 'recoil'
import { localStorageRecoilEffect } from '../utils/local-storage-recoil-effect'

interface UserProps {
  email: string
}

export const userState = atom<null | UserProps>({
  key: 'userState', // unique ID (with respect to other atoms/selectors)
  default: null, // default value (aka initial value)
  effects_UNSTABLE: [localStorageRecoilEffect('userState')],
})