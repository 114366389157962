import { ReactElement, useCallback } from 'react'
// CSS
import './editor-asset-list-item.scss'

import { useRecoilState, useRecoilValue } from 'recoil'
import { editedItemState } from '../../../../states/edited-item.state'
import classNames from 'classnames'
import NameTag from '../../../name-tag/name-tag'
import { itemAssetState } from '../../../../states/item-asset.state'
import { AssetGroupId } from '../../../../states/asset-groups.state'
import { assetGroup } from '../../../../states/asset-group'
import {AssetItem} from "../../../../types/types";

interface Props {
  readonly assetId?: string
  readonly groupId: AssetGroupId
}

const useIsAssetInUse = (item, assetId, groupName) => {
  let isMatch = false

  Object.keys(item).forEach((key) => {
    // if (Array.isArray(item[key]) && item[key].includes(assetId)) {
    //   isMatch = true
    //   return
    // }

    if (item[key] === assetId && key === groupName) {
      isMatch = true
      return
    }
  })
  return isMatch
}

const EditorAssetListItem = (props: Props): ReactElement => {
  const { assetId, groupId } = props

  const [editItem, setEditItem] = useRecoilState(editedItemState)
  const group = useRecoilValue(assetGroup(groupId))
  const asset = useRecoilValue(itemAssetState(assetId))
  const isInUse = useIsAssetInUse(editItem, assetId, group.name)
  const complementaryAsset = useRecoilValue(itemAssetState(asset?.complementary_asset))
  const complementaryAssetGroup = useRecoilValue(assetGroup(complementaryAsset?.group))

  /**
   * On Press modify current edit item
   */
  const onPress = useCallback(() => {
    const editKey = group.name
    /**
     * Update property
     */
    setEditItem((value) => {
      let newAssetValue: string | string[] = assetId
      if (editKey === 'accessories') {
        // Logic for accessories
        // If is there
        if (value.accessories.includes(assetId)) {
          newAssetValue = value.accessories.filter((item) => item !== assetId)
        } else {
          // Add new accessory
          newAssetValue = [...value.accessories, assetId]
        }
      }

      const complementary = complementaryAsset ? {
        [complementaryAssetGroup.name]: complementaryAsset.id
      } : {}

      return {
        ...value,
        [editKey]: newAssetValue,
        ...complementary
      }
    })
  }, [assetId, setEditItem, group.name])

  if (!assetId) {
    return (
      <div
        title={assetId}
        className={classNames({
          'editor-asset-list-item': true,
          active: isInUse,
        })}
        onClick={onPress}>
        <NameTag name={'Empty'} />
      </div>
    )
  }

  return (
    <div
      title={assetId}
      className={classNames({
        'editor-asset-list-item': true,
        active: isInUse,
      })}
      onClick={onPress}>
      <div dangerouslySetInnerHTML={{ __html: asset.source }} className="layer" />
      <NameTag name={asset?.name} />
    </div>
  )
}

export default EditorAssetListItem