import { ReactElement } from 'react'
import { useRecoilValue } from 'recoil'
import { assetGroupsState } from '../../states/asset-groups.state'
import AssetsGalleryGroup from './assets-gallery-group'
import { Scrollbars } from 'react-custom-scrollbars-2'

const AssetsGallery = (): ReactElement => {
  const assetGroups = useRecoilValue(assetGroupsState)

  return (
    <Scrollbars
      style={{
        display: 'flex',
        width: '100%',
        height: 'calc(100vh - 3rem)',
      }}>
      <div>
        <div></div>
        {assetGroups.map((group) => (
          <AssetsGalleryGroup group={group} key={group.id} />
        ))}
      </div>
    </Scrollbars>
  )
}

export default AssetsGallery