import React, { ReactElement, useCallback } from 'react'
import { AssetItem } from '../../types/types'
import { useRecoilValue } from 'recoil'
import { assetVariantsState } from '../../states/asset-variants.state'
import GalleryItemAuthor from '../gallery/gallery-item/gallery-item-author'
import { IoBrushOutline, IoTrashOutline } from 'react-icons/all'
import dummy from './dummy.png'

interface Props {
  item: AssetItem
}

const AssetsGalleryItem = (props: Props): ReactElement => {
  const { item } = props
  const variants = useRecoilValue(assetVariantsState(item.id))

  const goToEditor = useCallback(() => {
    // Noop
  }, [])

  const deleteAsset = useCallback(() => {
    // Noop
  }, [])

  return (
    <div>
      <div className="gallery-item">
        <div className="gallery-item__image">
          <div
            className="gallery-item__svg"
            dangerouslySetInnerHTML={{ __html: item.source }}
            // onClick={onPreviewPressed}
          />
          <img src={dummy} style={{ opacity: 0.05 }} />
        </div>
        <div className="gallery-item__info">
          <div className="gallery-item__title">{item.name}</div>
          <GalleryItemAuthor authorId={item.author} />
          <br />
          Variants: {variants.length}
        </div>

        <div className="gallery-item__options">
          <button onClick={goToEditor} className="button neutral">
            <IoBrushOutline size={24} />
          </button>
          <button onClick={deleteAsset} className="button neutral" disabled>
            <IoTrashOutline color="#ccc" size={24} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AssetsGalleryItem)