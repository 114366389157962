import axios from 'axios'
import { REACT_APP_REST_API_ENDPOINT } from '../env'
import { useCallback } from 'react'
import { CollectionItem } from '../types/types'
import { getRequestConfig } from '../utils/get-request-config'

export const useUploadAssets = () => {
  const endpoint = REACT_APP_REST_API_ENDPOINT + '/upload-asset'

  return useCallback(
    async (data): Promise<CollectionItem | false> => {
      try {
        const result = await axios.post(endpoint, data, getRequestConfig())
        alert(result)

        return result.data.item
      } catch (e) {
        return false
      }
    },
    [endpoint],
  )
}