import axios from 'axios'
import { REACT_APP_REST_API_ENDPOINT } from '../env'
import { useCallback } from 'react'
import { getRequestConfig } from '../utils/get-request-config'

interface ErrorMessage {
  error: string
}

export const useRandomizeItem = (item_id) => {
  const endpoint = REACT_APP_REST_API_ENDPOINT + '/randomize/' + item_id

  return useCallback(async (): Promise<true | ErrorMessage> => {
    try {
      const result = await axios.put(endpoint, null, getRequestConfig())

      if (result) {
        return true
      }
    } catch (e) {
      alert('Network Error: Check auth server is running')
      return {
        error: 'Server error',
      }
    }
  }, [endpoint])
}