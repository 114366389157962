import {atom, selectorFamily} from 'recoil'
import {AssetItem} from "../types/types";
import {assetsState} from "./assets.state";
import {AssetGroupId} from "./asset-groups.state";


export const assetParentsState = selectorFamily<AssetItem[], AssetGroupId>({
  key: 'assetParentsState',
  get:
    (assetGroupId: AssetGroupId) =>
      ({ get }) => {
        /**
         * Get collection data
         */
        return get(assetsState).filter(item=> item.group === assetGroupId && item.parent === item.id)

      },
})