import React, { ReactElement, useCallback } from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { itemAssetState } from '../../../states/item-asset.state'
import {
  BiEdit,
  BsArrowUpLeftSquareFill,
  IoLockClosedOutline,
  IoLockOpenOutline,
} from 'react-icons/all'
import { editedAssetIdState } from '../../../states/edited-asset-id.state'
import { referencedAssetIdState } from '../../../states/referenced-asset-id.state'
import { CollectionItem } from '../../../types/types'
import { updateCollectionItem } from '../../../database/update-collection-item'
import { collectionItemState } from '../../../states/collection-item.state'
import classNames from 'classnames'
import { editedItemState } from '../../../states/edited-item.state'

interface Props {
  assetId: string
  group: string
  itemData?: CollectionItem
}

const EditorInfoItem = (props: Props): ReactElement => {
  const { assetId, group, itemData } = props

  const asset = useRecoilValue(itemAssetState(assetId))
  const [editAssetId, setEditAssetId] = useRecoilState(editedAssetIdState)
  const setRefAssetId = useSetRecoilState(referencedAssetIdState)
  const isLocked = itemData.locked_layers.includes(group)
  const setEditedData = useSetRecoilState(editedItemState)

  const setEditableAsset = useCallback(() => {
    setEditAssetId(assetId)
  }, [assetId, setEditAssetId])

  const setReferenceAsset = useCallback(() => {
    setRefAssetId(assetId)
  }, [assetId, setRefAssetId])

  const isEdited = editAssetId === assetId
  const canBeReference = !isEdited && editAssetId

  const hasEditableAsset = !editAssetId && assetId
  const hasReferenceAsset = canBeReference && assetId

  const onLockedPress = useCallback(async () => {
    let new_layers = [...itemData.locked_layers]

    if (isLocked) {
      const index = itemData.locked_layers.indexOf(group)

      new_layers.splice(index, 1)
    } else {
      new_layers = [...itemData.locked_layers, group]
    }

    const updatedData = await updateCollectionItem({ ...itemData, locked_layers: new_layers })

    if (updatedData) {
      setEditedData(updatedData)
    }
  }, [setEditedData, group, itemData, isLocked])

  return (
    <tr>
      <td>{group.toUpperCase()}</td>
      <td>{asset?.name}</td>
      <td>
        {hasEditableAsset ? (
          <button onClick={setEditableAsset} className="button compact neutral">
            <BiEdit />
          </button>
        ) : null}
        {hasReferenceAsset ? (
          <button className="button compact fun">
            <BsArrowUpLeftSquareFill onClick={setReferenceAsset} />
          </button>
        ) : null}
      </td>
      <td>
        <button
          className={classNames({
            button: true,
            compact: true,
            locked: isLocked,
            positive: !isLocked,
          })}
          onClick={onLockedPress}>
          {isLocked ? <IoLockClosedOutline /> : <IoLockOpenOutline />}
        </button>
      </td>
    </tr>
  )
}

export default React.memo(EditorInfoItem)