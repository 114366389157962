import axios from 'axios'
import { REACT_APP_REST_API_ENDPOINT } from '../env'
import { AssetItem } from '../types/types'
import { getRequestConfig } from '../utils/get-request-config'

export const addAssetItem = async (data): Promise<AssetItem> => {
  const endpoint = REACT_APP_REST_API_ENDPOINT + '/asset'

  const result = await axios.post(
    endpoint,
    {
      ...data,
    },
    getRequestConfig(),
  )

  return result.data.item
}