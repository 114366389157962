import { ReactElement, useCallback, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useHistory } from 'react-router-dom'
import { useLogin } from '../database/use-login'

interface FormErrors {
  password?: string
  username?: string
}

const initialValues = { username: null, password: null }

const LoginForm = (): ReactElement => {
  const navigation = useHistory()
  const loginRequest = useLogin()
  const [error, setError] = useState(null)

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const result = await loginRequest(values)

      if (result === true) {
        setSubmitting(false)
        navigation.push('/collection')
      } else {
        setError(result?.error)
      }
    },
    [loginRequest, navigation],
  )

  return (
    <div className="form">
      <Formik
        key="login-form"
        initialValues={initialValues}
        validate={(values) => {
          const errors: FormErrors = {}

          if (!values.username) {
            errors.username = 'Username is required'
          }
          if (!values.password) {
            errors.password = 'Password is required'
          }

          return errors
        }}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <Field type="text" name="username" placeholder="Username" />
            <ErrorMessage name="username" component="div" className="error-message" />
            <Field type="password" name="password" placeholder="Password" />
            <ErrorMessage name="password" component="div" className="error-message" />
            <div>
              <button type="submit" className="neutral" disabled={isSubmitting}>
                {isSubmitting ? 'Verifying..' : 'Login'}
              </button>
            </div>
            {error && <div className="error">{error}</div>}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default LoginForm