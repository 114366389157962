import { selector } from 'recoil'

import { assetsState } from './assets.state'

export const parentAssets = selector<any>({
  key: 'parentAssets',
  get: ({ get }) => {
    /**
     * Get collection data
     */
    const assets = get(assetsState)

    return assets.filter((searchItem) => {
      // Get parents only
      return searchItem.id === searchItem.parent
    })
  },
})