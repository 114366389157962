import axios from 'axios'
import { REACT_APP_REST_API_ENDPOINT } from '../env'
import { AssetItem } from '../types/types'
import { getRequestConfig } from '../utils/get-request-config'

export const updateAssetItem = async (data): Promise<AssetItem> => {
  const endpoint = REACT_APP_REST_API_ENDPOINT + '/asset/' + data.id

  const result = await axios.put(
    endpoint,
    {
      ...data,
    },
    getRequestConfig(),
  )

  return result.data.item
}