import {atom, selectorFamily} from 'recoil'
import {AssetItem} from "../types/types";
import {assetsState} from "./assets.state";
import {AssetId} from "./item-asset.state";


export const assetVariantsState = selectorFamily<AssetItem[], AssetId>({
  key: 'assetVariantsState',
  get:
    (parentId: AssetId) =>
      ({ get }) => {
        return get(assetsState).filter(item=> item.parent === parentId)
      },
})