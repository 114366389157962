import React, { ReactElement, useCallback } from 'react'
import './color-combination-set.scss'
import ColorCombinationInput from './color-combination-input'
import classNames from 'classnames'
import { AiOutlineDelete } from 'react-icons/all'
interface Props {
  colorCount?: number
  combination: any
  combinationIndex: number
  onChange: (ColorCombination, index) => any
  onRemovePress: (combinationIndex: number) => void
}

export type ColorCombination = {
  id: string
  name: string
  colors: Record<number, string>
  isLocked: boolean
  parent_colors: string[]
}

const ColorReplacementSet = (props: Props): ReactElement => {
  const { colorCount, combination, onChange, combinationIndex, onRemovePress } = props

  const onNameChange = useCallback(
    (event) => {
      combination.name = event.target.value

      onChange(combination, combinationIndex)
    },
    [onChange, combination, combinationIndex],
  )

  const onRemovePressed = useCallback(() => {
    onRemovePress(combinationIndex)
  }, [onRemovePress, combinationIndex])

  return (
    <div className="color-combination-set">
      <div>
        <div className="flex">
          <div className="flex" style={{ flex: 1 }}>
            <h4>Color combination ({combination.id}) #</h4>
          </div>
          <div>
            {!combination.isLocked && (
              <button className="button neutral danger" onClick={onRemovePressed}>
                <AiOutlineDelete color="#995555" size={18} />
              </button>
            )}
          </div>
        </div>
        <input
          className={classNames({
            error: !combination.name || combination.name.length === 0,
          })}
          name="name"
          value={combination.name}
          placeholder="Combination name"
          onChange={onNameChange}
        />
      </div>
      <div className="color-combination-set__colors">
        {[...Array(colorCount)].map((_something, localIndex) => {
          const color = combination.colors[localIndex]

          return (
            <ColorCombinationInput
              key={localIndex.toString() + combinationIndex.toString()}
              color={color}
              isLocked={combination.isLocked}
              index={localIndex}
              combinationIndex={combinationIndex}
              combination={combination}
              onChange={onChange}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ColorReplacementSet