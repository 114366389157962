import React, { ReactElement } from 'react'
import { useRecoilValue } from 'recoil'
import { assetParentsState } from '../../states/asset-parents.state'
import { AssetGroup } from '../../types/types'
import AssetsGalleryItem from './assets-gallery-item'

interface Props {
  group: AssetGroup
}

const AssetsGalleryGroupUI = (props: Props): ReactElement => {
  const { group } = props

  const assets = useRecoilValue(assetParentsState(group.id))

  if (group.is_complementary) {
    return null
  }

  return (
    <div style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
      <h2>
        {group.name} ({assets.length})
      </h2>
      <div style={{ display: 'flex' }}>
        {assets.map((item) => (
          <AssetsGalleryItem item={item} key={item.id} />
        ))}
      </div>
    </div>
  )
}

const AssetsGalleryGroup = React.memo(AssetsGalleryGroupUI)
export default AssetsGalleryGroup