import React, { ReactElement, useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { collectionItemState } from '../../../states/collection-item.state'
import { useHistory } from 'react-router-dom'
// CSS
import './gallery-item.scss'
import { zeroPad } from '../../../utils/numZeroPadding'

import GalleryItemAuthor from './gallery-item-author'
import {
  IoBrush,
  IoBrushOutline,
  IoInformationCircleOutline,
  IoTrashOutline,
} from 'react-icons/all'

interface Props {
  id: string
  onPreviewPress: (id: string) => void
}

const GalleryItem = (props: Props): ReactElement => {
  const { id, onPreviewPress } = props
  const navigation = useHistory()
  const itemData = useRecoilValue(collectionItemState(id))
  const goToEditor = useCallback(() => {
    navigation.push('/editor/' + id)
  }, [id, navigation])

  const onPreviewPressed = useCallback(() => {
    onPreviewPress(id)
  }, [id, onPreviewPress])

  return (
    <div className="gallery-item">
      <div className="gallery-item__image">
        <div
          className="gallery-item__svg"
          dangerouslySetInnerHTML={{ __html: itemData.source }}
          onClick={onPreviewPressed}
        >
        {/*<img src={"media/girls/"+itemData.image_uri} />*/}
        </div>
      </div>
      <div className="gallery-item__info">
        <div className="gallery-item__title">
          {itemData.name} {zeroPad(itemData.order, 5)}
        </div>
        <GalleryItemAuthor authorId={itemData.author} />
        <br />
        <div className="gallery-item__hashtags">
          #{itemData?.magic_word_1} #{itemData?.magic_word_2} #{itemData?.magic_word_3}
        </div>
      </div>

      <div className="gallery-item__options">
        <button onClick={goToEditor} className="button neutral">
          <IoBrushOutline size={24} />
        </button>
        <button onClick={onPreviewPressed} className="button neutral">
          <IoInformationCircleOutline size={24} />
        </button>
        <button className="button neutral" disabled>
          <IoTrashOutline color="#ccc" size={24} />
        </button>
      </div>
    </div>
  )
}

export default React.memo(GalleryItem)