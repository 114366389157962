import React, { ReactElement, useEffect, useRef, useState } from 'react'
import EditorAssetList from '../editor-asset-list/editor-asset-list'
import { Scrollbars } from 'react-custom-scrollbars-2'

// CSS
import './editor-asset-gallery.scss'
import EditorAssetGalleryItem from './editor-asset-gallery-item'

import { useRecoilValue } from 'recoil'
import { assetGroupsState } from '../../../states/asset-groups.state'
import { editedItemState } from '../../../states/edited-item.state'
import { assetGroup } from '../../../states/asset-group'

const EditorAssetGallery = (): ReactElement => {
  const lastlySelected = window.localStorage.getItem('last_group')

  const [selected, setSelected] = useState<string>(lastlySelected)
  const groupData = useRecoilValue(assetGroup(selected))
  console.log(selected)
  const { locked_layers } = useRecoilValue(editedItemState)
  const isLocked = locked_layers.includes(groupData?.name)
  const divRef = useRef(null)
  const [height, setHeight] = useState(0)

  const navigationItems = useRecoilValue(assetGroupsState)

  useEffect(() => {
    setHeight(divRef?.current?.clientHeight)
  }, [])

  useEffect(() => {
    if (!selected && navigationItems.length > 0) {
      setSelected(navigationItems[0].id)
    }
  }, [selected, navigationItems])

  if (navigationItems.length === 0 || !selected) {
    return null
  }

  return (
    <div className="editor-asset-gallery" ref={divRef}>
      <Scrollbars style={{ width: '2.5rem', height: height }} autoHide>
        <ul className="tabs">
          {navigationItems.map((item) => {
            if (locked_layers.includes(item.name)) {
              return null
            }

            return (
              <EditorAssetGalleryItem
                onPress={setSelected}
                groupId={item.id}
                label={item.name}
                isActive={selected === item.id}
                key={item.id}
              />
            )
          })}
        </ul>
      </Scrollbars>
      <EditorAssetList selectedGroupId={selected} isLocked={isLocked} />
    </div>
  )
}

export default React.memo(EditorAssetGallery)