import React, { ReactElement } from 'react'

// CSS
import './editor-info.scss'
import { useRecoilValue } from 'recoil'
import { editedItemState } from '../../../states/edited-item.state'
import ItemForm from '../../../forms/item-form'
import EditorInfoTable from '../editor-info-table/editor-info-table'
import RandomizeButton from '../../../containers/randomize-button'

const EditorInfo = (): ReactElement => {
  const editedData = useRecoilValue(editedItemState)

  return (
    <div className="editor-info">
      <h3>Modifications</h3>
      <EditorInfoTable data={editedData} />
      <br />
      <RandomizeButton data={editedData} />
      <h3>Options</h3>
      <ItemForm initialValues={editedData} />
    </div>
  )
}

export default React.memo(EditorInfo)