import { selectorFamily } from 'recoil'
import { AssetGroupId, assetGroupsState } from './asset-groups.state'

export const assetGroup = selectorFamily<any, AssetGroupId>({
  key: 'assetGroup',
  get:
    (assetGroupId: AssetGroupId) =>
    ({ get }) => {
      /**
       * Get collection data
       */
      const data = get(assetGroupsState)

      return data.find((searchItem) => {
        return searchItem.id === assetGroupId
      })
    },
})