import { selectorFamily } from 'recoil'

import { AuthorId } from '../types/types'
import { authorsState } from './authors.state'

export const author = selectorFamily<any, AuthorId>({
  key: 'author',
  get:
    (authorId: AuthorId) =>
    ({ get }) => {
      /**
       * Get collection data
       */
      const data = get(authorsState)
      console.log(data)
      return data.find((searchItem) => {
        return searchItem.id === authorId
      })
    },
})