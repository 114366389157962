import { ReactElement } from 'react'
import './editor-layer.scss'
import { useRecoilValue } from 'recoil'
import { editedItemState } from '../../states/edited-item.state'
import { itemAssetState } from '../../states/item-asset.state'
import { AssetGroupName } from '../../types/types'
import { editedAssetState } from '../../states/edited-asset.state'
import { editedAssetIdState } from '../../states/edited-asset-id.state'

interface Props {
  group: AssetGroupName
}

const EditorLayer = (props: Props): ReactElement => {
  const { group } = props

  const layer = useRecoilValue(editedItemState)
  let assetId = layer[group]
  assetId = Array.isArray(assetId) ? '' : assetId
  const data = useRecoilValue(itemAssetState(assetId))
  const editedAssetId = useRecoilValue(editedAssetIdState)
  const editedData = useRecoilValue(editedAssetState)

  const isEdited = editedAssetId === assetId

  if (!data?.source || !data) {
    return null
  }

  return (
    <div className="editor-layer">
      <div dangerouslySetInnerHTML={{ __html: isEdited ? editedData?.source : data?.source }} />
    </div>
  )
}

export default EditorLayer