import { selectorFamily } from 'recoil'
import { CollectionItem } from '../types/types'
import { collectionState } from './collection.state'

export const collectionItemState = selectorFamily<CollectionItem, string>({
  key: 'collectionItemState',
  get:
    (itemId: string) =>
    ({ get }) => {
      /**
       * Get collection data
       */
      const data = get(collectionState)

      return data.find((searchItem) => {
        return searchItem.id === itemId
      })
    },
  set:
    (itemId) =>
    ({ set, get }, newValue: CollectionItem) => {
      const originalData = get(collectionState)

      let newData = [...originalData]
      let objectIndex = originalData.findIndex((obj) => obj.id === itemId)
      // Update item on index
      newData[objectIndex] = newValue
      // Save new item
      set(collectionState, newData)
    },
})