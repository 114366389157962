import { ReactElement, useCallback } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { CollectionItem } from '../types/types'
import { useSetRecoilState } from 'recoil'
import { collectionItemState } from '../states/collection-item.state'
import { useHistory } from 'react-router-dom'
import { updateCollectionItem } from '../database/update-collection-item'
interface FormErrors {
  email?: string
}

interface Props {
  initialValues: CollectionItem
}

const ItemForm = (props: Props): ReactElement => {
  const { initialValues } = props
  const navigation = useHistory()
  const setCollectionItem = useSetRecoilState(collectionItemState(initialValues.id))

  const updateItem = useCallback(
    async (data) => {
      // Generate search terms

      // This is a little bit walkaround. There is no support of async setter inside the selector
      const updatedData = await updateCollectionItem(data)

      if (updatedData) {
        setCollectionItem(updatedData)
        navigation.push('/collection')
        return true
      }
      return false
    },
    [setCollectionItem, navigation],
  )

  return (
    <div>
      <Formik
        key={'form-item-' + initialValues.id}
        initialValues={{
          name: initialValues.name,
          order: initialValues.order,
          magic_word_1: initialValues.magic_word_1,
          magic_word_2: initialValues.magic_word_2,
          magic_word_3: initialValues.magic_word_3,
        }}
        validate={(values) => {
          const errors: FormErrors = {}
          return errors
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const status = await updateItem({
            ...initialValues,
            name: values.name,
            order: values.order,
            magic_word_1: values.magic_word_1,
            magic_word_2: values.magic_word_2,
            magic_word_3: values.magic_word_3,
          })
          if (status) {
            setSubmitting(false)
          }
        }}>
        {({ isSubmitting }) => (
          <Form>
            <Field type="number" name="order" placeholder="Order No." />
            <Field type="text" name="name" placeholder="NFT Name" />
            <Field type="text" name="magic_word_1" placeholder="Magic Word 1" />
            <Field type="text" name="magic_word_2" placeholder="Magic Word 2" />
            <Field type="text" name="magic_word_3" placeholder="Magic Word 3" />
            <Field type="text" name="search_tags" as="textarea" placeholder="Search tags" />
            <ErrorMessage name="email" component="div" />
            <div>
              <button type="submit" className="danger" disabled={isSubmitting}>
                {isSubmitting ? 'Saving..' : 'Save'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ItemForm