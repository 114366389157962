import { Col, Row } from 'react-flexbox-grid'
import ColorInput from '../color-input/color-input'
import ColorReplacementSet, {
  ColorCombination,
} from '../color-replacement-set/color-replacement-set'
import ColorCombinationPreview from '../color-combination-preview/color-combination-preview'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { nanoid } from 'nanoid'
import AssetForm from '../../../forms/asset-form'

interface Props {
  originColors: string[]
  source?: string
}

const emptyArray = []

const emptyCombination: ColorCombination = {
  id: '',
  name: '',
  colors: ['#000'],
  parent_colors: [],
  isLocked: false,
}

const ColorCombinations = (props: Props): ReactElement => {
  const { originColors, source } = props
  const [colorCombinations, setColorCombinations] = useState({ combinations: emptyArray })
  // const [inputColors, setInputColors] = useState({ colors: emptyArray })

  const onInitialLoad = useCallback((colors) => {
    const newUniqueSet = Object.assign({}, emptyCombination)
    newUniqueSet.colors = [...colors]
    newUniqueSet.id = nanoid(5)
    newUniqueSet.parent_colors = [...colors]
    newUniqueSet.name = 'Default - please rename'
    newUniqueSet.isLocked = true

    setColorCombinations((state) => {
      const newState = [newUniqueSet]
      return { combinations: newState }
    })
  }, [])

  useEffect(() => {
    if (colorCombinations.combinations.length === 0 && originColors) {
      onInitialLoad(originColors)
    }
  }, [colorCombinations.combinations, originColors, onInitialLoad])

  /**
   * Add a new color variant of the asset
   */
  const addCombination = useCallback(() => {
    const newUniqueSet = Object.assign({}, emptyCombination)
    newUniqueSet.colors = [...originColors]
    newUniqueSet.id = nanoid(5)

    setColorCombinations((state) => {
      const newState = [...state.combinations, newUniqueSet]
      return { combinations: newState }
    })
  }, [originColors])

  const removeCombination = useCallback((combinationIndex: number) => {
    setColorCombinations((oldState) => {
      const newState = Object.assign({}, oldState)
      const combinations = oldState.combinations
      delete combinations[combinationIndex]

      newState.combinations = combinations
      return newState
    })
  }, [])

  // /**
  //  * Color change works
  //  */
  // const onColorChange = useCallback(
  //   (color, index) => {
  //     const newState = Object.assign({}, originColors)
  //     newState.colors[parseInt(index)] = color
  //     setInputColors(newState)
  //   },
  //   [originColors],
  // )

  const onCombinationChange = useCallback((combination: ColorCombination, index) => {
    setColorCombinations((oldState) => {
      const newState = Object.assign({}, oldState)
      newState.combinations[parseInt(index)] = combination

      return newState
    })
  }, [])

  return (
    <>
      <Row>
        <Col lg={12}>
          <h2>Colors</h2>
          <h3>Extracted colors</h3>
          <p>
            Pokud vidíte více barev než jste použili, je pravděpodobné, že jste exportovali více než
            jste chtěli. Případně máte v modelu použité pozůstatky barev z předchozí práce.
          </p>
          <div className="flex flex-justify-">
            {originColors.map((item, index) => {
              return (
                <ColorInput key={index.toString()} index={index} color={item} isEditable={false} />
              )
            })}
          </div>
        </Col>
        <Col lg={12}>
          <h3>Asset Color Combinations ({colorCombinations.combinations.length})</h3>
          <div>{JSON.stringify(originColors)}</div>
          {colorCombinations.combinations.map((combination, index) => {
            return (
              <div className="flex" style={{ marginBottom: '1rem' }}>
                <ColorReplacementSet
                  key={combination.id}
                  colorCount={originColors.length}
                  combination={combination}
                  onChange={onCombinationChange}
                  combinationIndex={index}
                  onRemovePress={removeCombination}
                />
                <ColorCombinationPreview
                  combination={combination}
                  sourceData={source}
                  defaultColors={[...originColors]}
                  key={index.toString()}
                />
              </div>
            )
          })}
          <button
            onClick={addCombination}
            disabled={originColors.length === 0}
            className="button fun">
            Add Color Variant
          </button>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <h2>Asset Info</h2>
          <AssetForm
            colorVariants={colorCombinations.combinations}
            source={source}
            defaultColors={[...originColors]}
          />
          <br />
        </Col>
      </Row>
    </>
  )
}
export default ColorCombinations