import * as React from 'react'
import {IntlProvider as IntlReactProvider} from 'react-intl'
import {PropsWithChildren, ReactElement} from "react";

// Translated messages in French with matching IDs to what you declared
const messagesInCzech = {
  welcome: "hello biatch",
}

type Content = PropsWithChildren<any>

export default function IntlProvider(props: Content): ReactElement {

  const { children } = props

  return (
    <IntlReactProvider messages={messagesInCzech} locale="cs" defaultLocale="cs">
      {children}
    </IntlReactProvider>
  )
}