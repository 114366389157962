import React, {ReactElement, useCallback, useEffect, useRef, useState} from 'react'

// CSS
import './editor-asset-list.scss'

import EditorAssetListItem from './editor-asset-list-item/editor-asset-list-item'
import {useRecoilState, useRecoilValue} from 'recoil'
import { itemAssetsIdsState } from '../../../states/item-assets-ids.state'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { AssetGroupId } from '../../../states/asset-groups.state'
import { assetGroup } from '../../../states/asset-group'
import CenterComponent from '../../center-component/center-component'
import { IoLockClosed } from 'react-icons/all'
import {assetSearch} from "../../../states/asset-search";

interface Props {
  selectedGroupId: AssetGroupId
  isLocked: boolean
}

const EditorAssetList = (props: Props): ReactElement => {
  const { selectedGroupId, isLocked } = props

  const [search, setSearch] = useRecoilState(assetSearch)
  const assetsIds = useRecoilValue(itemAssetsIdsState(selectedGroupId))
  const assetGroupData = useRecoilValue(assetGroup(selectedGroupId))
  const divRef = useRef(null)
  const [height, setHeight] = useState(0)



  useEffect(() => {
    setHeight(divRef?.current?.clientHeight)
  }, [])

  const onSearchChange = useCallback((event) => {
    const value = event.target.value
    setSearch(value)
  }, [])

  if (!assetGroupData) {
    return null
  }

  return (
    <div className="editor-asset-list">
      {isLocked ? (
        <div
          style={{
            padding: '1rem',
            textAlign: 'center',
            marginTop: '1rem',
          }}>
          <div style={{ marginBottom: '1rem' }}>
            <IoLockClosed size={42} />
          </div>
          <strong>This trait is locked</strong>
          <div style={{ fontSize: '0.85rem', marginTop: '0.5rem' }}>
            You can unlock it by clicking on "lock" icon in right panel.
          </div>
        </div>
      ) : (
        <>
          <input value={search} onChange={onSearchChange} name="search" placeholder="Search asset.."/>
          <Scrollbars
            style={{ height: height, flex: 1, width: '13.75rem', marginTop: '0.5rem' }}
            autoHide
            renderTrackHorizontal={(props) => (
              <div {...props} className="track-horizontal" style={{ display: 'none' }} />
            )}
            renderThumbHorizontal={(props) => (
              <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />
            )}>
            <div className="editor-asset-list__list">
              <EditorAssetListItem assetId={null} key="zero-item" groupId={selectedGroupId} />
              {assetsIds.map((itemId) => {
                return (
                  <EditorAssetListItem assetId={itemId} key={itemId} groupId={selectedGroupId} />
                )
              })}
            </div>
          </Scrollbars>
        </>
      )}
    </div>
  )
}

export default EditorAssetList