import React, {
  ForwardRefRenderFunction,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import Modal from 'react-modal'

interface Props {
  source?: string
}

export type PreviewBoxHandle = {
  close: () => void
  open: () => void
}

const PreviewBox: ForwardRefRenderFunction<PreviewBoxHandle, Props> = (
  props: Props,
  ref,
): ReactElement => {
  const { source } = props
  const [modalIsOpen, setIsOpen] = useState(false)

  const closeModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  const openModal = useCallback(() => {
    setIsOpen(true)
  }, [])

  useImperativeHandle(ref, () => ({
    close: closeModal,
    open: openModal,
  }))

  if (!source) {
    return null
  }

  return (
    <Modal isOpen={!!modalIsOpen} onRequestClose={closeModal} contentLabel="Example Modal">
      <div className="gallery-list__preview" dangerouslySetInnerHTML={{ __html: source }} />
      <button onClick={closeModal}>close</button>
    </Modal>
  )
}

export default React.forwardRef(PreviewBox)