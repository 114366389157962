import React, { ReactElement, useCallback, useState } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { collectionItemIdsState } from '../../states/collection-item-ids.state'

// CSS
import './navigation.scss'
import { useCreateItem } from '../../database/add-collection-item'
import { userState } from '../../states/user-state'
import { IoLogOutOutline } from 'react-icons/all'
import {assetsState} from "../../states/assets.state";
import {assetParentsState} from "../../states/asset-parents.state";

/**
 * Navigation
 * @title
 */
const Navigation = (): ReactElement => {
  const collectionCount = useRecoilValue(collectionItemIdsState).length
  const setUserState = useSetRecoilState(userState)
  const navigation = useHistory()
  const route = useLocation()
  const createItem = useCreateItem()
  const [waiting, setWaiting] = useState(false)
  const [creatingError, setCreatingError] = useState(false)
  const isEditor = route.pathname.startsWith('/editor')
  const assetsCount = useRecoilValue(assetsState).length
  const createNewItem = useCallback(async () => {
    setWaiting(true)

    const result = await createItem()

    if (result) {
      setWaiting(false)
      navigation.push('/editor/' + result?.id)
    } else {
      setWaiting(false)
      setCreatingError(true)
    }
  }, [createItem, navigation])

  const logout = useCallback(() => {
    setUserState(null)
    navigation.push('/')
  }, [setUserState, navigation])

  return (
    <div className="navigation">
      <div className="navigation__list">
        <NavLink to="/" activeClassName="active" exact>
          Home
        </NavLink>
        <NavLink to="/collection" activeClassName="active">
          Collection ({collectionCount})
        </NavLink>
        <NavLink to="/assets" activeClassName="active">
          Assets ({assetsCount})
        </NavLink>
        <NavLink to="/asset-editor" activeClassName="active">
          Add asset
        </NavLink>
        {!isEditor && (
          <button onClick={createNewItem}>
            {waiting ? 'Creating...' : creatingError ? 'Error (500)' : 'Create Girl'}
          </button>
        )}
      </div>
      <button className="align-right" onClick={logout}>
        <IoLogOutOutline style={{ marginRight: '0.25rem' }} />
        Logout
      </button>
    </div>
  )
}

export default React.memo(Navigation)