import { selector } from 'recoil'
import { collectionState } from './collection.state'

export const collectionItemIdsState = selector<string[]>({
  key: 'collectionItemIdsState',
  get: ({ get }) => {
    const data = get(collectionState)

    return data.map((a) => a.id)
  },
})