import axios from 'axios'
import { REACT_APP_REST_API_HOST } from '../env'
import { useSetRecoilState } from 'recoil'
import { useCallback } from 'react'
import { userState } from '../states/user-state'
import AuthToken from '../utils/auth-token'

interface ErrorMessage {
  error: string
}

export const useLogin = () => {
  const endpoint = REACT_APP_REST_API_HOST + '/auth/sign-in'
  const setUserState = useSetRecoilState(userState)

  return useCallback(
    async (data): Promise<true | ErrorMessage> => {
      try {
        const result = await axios.post(endpoint, data)
        console.log(result.status)
        if (result.status === 201) {
          // Valid, save token

          AuthToken.setToken(result.data.token)
          setUserState(result.data)

          return true
        }
      } catch (e) {
        alert('Network Error: Check auth server is running')
        return {
          error: 'Server error',
        }
      }
    },
    [endpoint, setUserState],
  )
}