import { useRecoilValue } from 'recoil'
import { collectionItemIdsState } from '../../states/collection-item-ids.state'
import { Scrollbars } from 'react-custom-scrollbars-2'
// CSS
import './gallery-list.scss'
import GalleryItem from './gallery-item/gallery-item'

import React, { useCallback, useState } from 'react'
import { collectionItemState } from '../../states/collection-item.state'
import PreviewBox, { PreviewBoxHandle } from '../preview-box/preview-box'
import CenterComponent from '../center-component/center-component'

const GalleryList = () => {
  const collectionItems = useRecoilValue(collectionItemIdsState)

  const [itemId, setIdemId] = useState<string>(null)
  const modalRef = React.createRef<PreviewBoxHandle>()

  const previewData = useRecoilValue(collectionItemState(itemId))

  const setSelectedItem = useCallback(
    (itemId) => {
      setIdemId(itemId)
      modalRef.current.open()
    },
    [modalRef],
  )

  return (
    <Scrollbars
      style={{
        display: 'flex',
        width: '100%',
        height: 'calc(100vh - 3rem)',
      }}>
      <div className="gallery-list">
        <div className="gallery-list__title">
          <h1>Collection</h1>
        </div>
        <div className="gallery-list__search">
          <input type="text" name="search" placeholder="Search..." />
        </div>
        <div className="gallery-list__layout">
          {collectionItems.map((itemId) => {
            return <GalleryItem id={itemId} key={itemId} onPreviewPress={setSelectedItem} />
          })}
          {collectionItems.length === 0 && (
            <CenterComponent style={{ flex: 1, justifyItems: 'center', alignItems: 'center' }}>
              Gallery is empty
            </CenterComponent>
          )}
        </div>
      </div>
      <PreviewBox ref={modalRef} source={previewData?.source} />
    </Scrollbars>
  )
}

export default GalleryList