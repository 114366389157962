import React, { ReactElement } from 'react'

import CenterComponent from '../components/center-component/center-component'
import LoginForm from '../forms/login-form'

const LoginScreen = (): ReactElement => {
  return (
    <CenterComponent>
      <h1>Login</h1>
      <LoginForm />
    </CenterComponent>
  )
}

export default LoginScreen