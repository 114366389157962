import React, { PropsWithChildren, ReactElement } from 'react'
import './center-component.scss'

type Props = PropsWithChildren<any>

/**
 *
 * @param props
 * @constructor
 */
const CenterComponent = (props: Props): ReactElement => {
  const { children } = props

  return <div className="center-component">{children}</div>
}

export default CenterComponent