import React, { ReactElement, useEffect } from 'react'

import Editor from '../components/editor/editor'
import EditorAssetGallery from '../components/editor/editor-asset-gallery/editor-asset-gallery'

import './editor-screen.scss'
import EditorInfo from '../components/editor/editor-info/editor-info'
import { EditorScreenParams } from '../types/types'
import { useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { collectionItemState } from '../states/collection-item.state'
import { editedItemState } from '../states/edited-item.state'
import EditorAssetColors from '../components/editor/editor-asset-colors/editor-asset-colors'
import { editedAssetIdState } from '../states/edited-asset-id.state'
import { SpinnerCircularSplit } from 'spinners-react'
import CenterComponent from '../components/center-component/center-component'

const EditorScreen = (): ReactElement => {
  const params: EditorScreenParams = useParams()

  const originalData = useRecoilValue(collectionItemState(params?.itemId))
  const editAssetId = useRecoilValue(editedAssetIdState)
  const [editedData, setEditedData] = useRecoilState(editedItemState)

  useEffect(() => {
    if ((!editedData && params?.itemId) || editedData.id !== params?.itemId) {
      /**
       * Update stats if not set
       */
      setEditedData(originalData)
    }
  }, [editedData, originalData, params?.itemId, setEditedData])

  if (!editedData) {
    return (
      <CenterComponent>
        <SpinnerCircularSplit color={'white'} secondaryColor={'#d0a5b2'} size={300} thickness={5} />
      </CenterComponent>
    )
  }

  return (
    <div className="editor-screen">
      <EditorAssetGallery />
      <div className="editor-screen__preview">
        <Editor />
      </div>
      {editAssetId && <EditorAssetColors />}
      <EditorInfo />
    </div>
  )
}

export default React.memo(EditorScreen)