import React, { ReactElement } from 'react'
import { CollectionItem } from '../../../types/types'

import './editor-info-table.scss'
import EditorInfoItem from '../editor-info-item/editor-info-item'
import { useRecoilValue } from 'recoil'
import { assetGroupsState } from '../../../states/asset-groups.state'

interface Props {
  data: CollectionItem
}

const EditorInfoTable = (props: Props): ReactElement => {
  const { data } = props
  const assetGroups = useRecoilValue(assetGroupsState)

  return (
    <table className="editor-info-table">
      <thead>
        <tr>
          <th className="text--left">Trait</th>
          <th className="text--left">Name</th>
          <th className="text--left">Edit</th>
          <th className="text--right" />
        </tr>
      </thead>
      <tbody>
        {assetGroups.map((group) => {
          return <EditorInfoItem key={group.name} assetId={data[group.name]} group={group.name} itemData={data} />
        })}
      </tbody>
    </table>
  )
}

export default EditorInfoTable