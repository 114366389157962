import { selectorFamily } from 'recoil'
import { AssetItem } from '../types/types'
import { assetsState } from './assets.state'

export type AssetId = string

export const itemAssetState = selectorFamily<AssetItem | null, AssetId>({
  key: 'itemAssetState',
  get:
    (assetId) =>
    ({ get }) => {
      const assets = get(assetsState)

      return assets.find((item) => item.id === assetId)
    },
  set:
    (assetId) =>
    ({ set, get }, newAssetValue: AssetItem) => {
      // ... Do a bunch of stuff when a user signs in ...
      const assets = get(assetsState)
      const newState = { ...assets }
      const indexOf = newState.findIndex((item) => item.id === assetId)
      newState[indexOf] = newAssetValue

      set(assetsState, newState)
    },
})