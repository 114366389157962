export const getSourceColors = (source: string): string[] => {
  if (!source) {
    return []
  }

  const fillsMatch = source?.toString().match(/#[a-fA-F0-9]{6}|#[a-fA-F0-9]{3}/g)

  return fillsMatch.filter(function (item, pos) {
    return fillsMatch.indexOf(item) === pos
  })
}