import React, { ReactElement } from 'react'

import './name-tag.scss'

interface Props {
  name: string
}

const NameTag = (props: Props): ReactElement => {
  const { name } = props

  return <div className="name-tag">@{name}</div>
}

export default React.memo(NameTag)