import { ReactElement, useCallback, useState } from 'react'
import ColorPicker from '../color-picker/color-picker'
import './color-input.scss'

interface Props {
  index?: number
  color: string
  onChange?: (string, number) => void
  isEditable?: boolean
}

const ColorInput = (props: Props): ReactElement => {
  const { index, color, onChange, isEditable } = props

  const onColorChange = useCallback(
    (event) => {
      onChange(event.target.value, index)
    },
    [index, onChange],
  )

  const onPickerChange = useCallback(
    (color) => {
      if (onChange) {
        onChange(color, index)
      }
    },
    [index, onChange],
  )

  const [isPickerVisible, setPickerVisible] = useState(false)

  const togglePicker = useCallback(() => {
    setPickerVisible((status) => !status)
  }, [])

  return (
    <div>
      {!!index && (
        <div className="flex flex--align-items-center">
          <label>Color {index + 1}</label>
        </div>
      )}
      <div className="flex flex--align-items-center">
        <div
          style={{
            backgroundColor: color,
            padding: '0.5rem',
            width: '41px',
            height: '41px',
            marginBottom: '8px',
          }}
        />
        <input
          placeholder="Enter default color in HEX"
          value={color}
          style={{ background: isEditable ? undefined : 'transparent' }}
          onChange={onColorChange}
          disabled={!isEditable}
          onFocus={togglePicker}
        />
      </div>
      <ColorPicker
        isVisible={isPickerVisible}
        color={color}
        onChange={onPickerChange}
        onCloseRequested={togglePicker}
      />
    </div>
  )
}

ColorInput.defaultProps = {
  isEditable: true,
}

export default ColorInput