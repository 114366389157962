import React, { ReactElement, useCallback, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { parentAssets } from '../states/parent-assets'

// TODO: Custom UI Docs: https://www.npmjs.com/package/react-confirm-alert
import { confirmAlert } from 'react-confirm-alert'
import { useReUploadAssets } from '../database/use-re-upload-assets'

interface Props {
  /**
   * SVG Source of the asset
   */
  source?: string

  /**
   * Original asset colors
   */
  originColors?: string[]
}

const ReUploadAssetForm = (props: Props): ReactElement => {
  const { source, originColors } = props
  const assets = useRecoilValue(parentAssets)
  const [parentId, setParentId] = useState(null)
  const reUploadAssets = useReUploadAssets()
  const onChange = useCallback((event) => {
    setParentId(event.target.value)
  }, [])

  const onConfirm = useCallback(async () => {
    const payload = {
      id: parentId, // Identification of the object
      source, // For new object
      originColors, // For update
    }

    await reUploadAssets(payload)
  }, [reUploadAssets, parentId, source, originColors])

  // const onSubmit = useCallback(() => {
  //   confirmAlert({
  //     title: 'Overwrite asset source?',
  //     message: 'Do you really want to overwrite the asset source data?',
  //     closeOnEscape: true,
  //     closeOnClickOutside: true,
  //     buttons: [
  //       {
  //         label: 'Sure!',
  //         // @ts-ignore
  //         onClick: onConfirm,
  //       },
  //       {
  //         label: 'Nope',
  //         // @ts-ignore
  //         onClick: () => alert('Click No'),
  //       },
  //     ],
  //   })
  // }, [onConfirm])

  const isSubmitAvailable = source && parentId

  return (
    <div>
      <h3>Re-upload asset</h3>
      <p>Do you want to re-upload one of the assets? Select parental asset</p>
      <select value={parentId} name="parentId" onChange={onChange}>
        <option>- Create a new asset</option>
        {assets.map((item) => {
          return <option value={item.id}>{item.name}</option>
        })}
      </select>

      {isSubmitAvailable && (
        <button className="button warning" onClick={onConfirm}>
          Re-upload asset
        </button>
      )}
    </div>
  )
}

export default ReUploadAssetForm