import axios from 'axios'
import { REACT_APP_REST_API_ENDPOINT } from '../env'
import { useCallback } from 'react'
import { CollectionItem } from '../types/types'
import { getRequestConfig } from '../utils/get-request-config'
import { Color } from '../states/types'

interface Payload {
  id: string
  source: string
  originColors: Color[]
}

export const useReUploadAssets = () => {
  const endpoint = REACT_APP_REST_API_ENDPOINT + '/re-upload-asset/'

  return useCallback(
    async (data: Payload): Promise<CollectionItem | false> => {
      try {
        const result = await axios.put(endpoint + data.id, data, getRequestConfig())
        alert(result)

        return result.data.item
      } catch (e) {
        alert(e)
        return false
      }
    },
    [endpoint],
  )
}