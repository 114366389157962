import axios from 'axios'
import { REACT_APP_REST_API_ENDPOINT } from '../env'
import { useSetRecoilState } from 'recoil'
import { collectionState } from '../states/collection.state'
import { useCallback } from 'react'
import { CollectionItem } from '../types/types'
import { getRequestConfig } from '../utils/get-request-config'

export const useCreateItem = () => {
  const endpoint = REACT_APP_REST_API_ENDPOINT + '/item/new'
  const setCollection = useSetRecoilState(collectionState)

  return useCallback(async (): Promise<CollectionItem | false> => {
    try {
      const result = await axios.post(endpoint, null, getRequestConfig())
      setCollection((collection) => [...collection, result.data.item])

      console.log(result)

      return result.data.item
    } catch (e) {
      return false
    }
  }, [endpoint, setCollection])
}