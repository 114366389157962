import axios from 'axios'
import { useSetRecoilState } from 'recoil'
import { collectionState } from '../states/collection.state'
import { REACT_APP_REST_API_ENDPOINT } from '../env'
import { useCallback, useEffect, useState } from 'react'
import { assetsState } from '../states/assets.state'
import { getRequestConfig } from '../utils/get-request-config'
import { assetGroupsState } from '../states/asset-groups.state'
import { authorsState } from '../states/authors.state'
import { seriesState } from '../states/series.state'

export const DatastoreInit = (): null => {
  const [requested, setRequested] = useState(false)
  const setCollection = useSetRecoilState(collectionState)
  const setAssets = useSetRecoilState(assetsState)
  const setAssetGroups = useSetRecoilState(assetGroupsState)
  const setAuthors = useSetRecoilState(authorsState)
  const setSeries = useSetRecoilState(seriesState)

  const initiate = useCallback(async () => {
    const result = await axios.get(REACT_APP_REST_API_ENDPOINT + '/items', getRequestConfig())

    setRequested(true)
    const { data } = result
    // Save all data from database
    setAssetGroups(data.assetGroups)
    setAssets(data.assets)
    setCollection(data.items)
    setAuthors(data.creators)
    setSeries(data.series)
  }, [setSeries, setAuthors, setAssetGroups, setAssets, setCollection])

  useEffect(() => {
    if (!requested) {
      initiate()
    }
  }, [requested, initiate])

  return null
}