import { ReactElement, useCallback } from 'react'
import { Formik, Form, Field } from 'formik'
import { CollectionItem } from '../types/types'
import { useRecoilValue } from 'recoil'
import { assetGroupsState } from '../states/asset-groups.state'
import { useUploadAssets } from '../database/use-upload-assets'
import { colorSetReplace } from '../utils/color-set-replace'
import {assetsState} from "../states/assets.state";

interface FormErrors {
  email?: string
}

interface Props {
  initialValues?: CollectionItem
  colorVariants: any
  defaultColors: any
  source: string
}

const INIT_VALUES = {
  name: '',
  source: '',
  tags: '',
  series: null,
  maxItems: 0,
  group: null,
  metaGroup: null,
}

const AssetForm = (props: Props): ReactElement => {
  const { colorVariants, source, defaultColors } = props
  const uploadAssets = useUploadAssets()
  const assetGroups = useRecoilValue(assetGroupsState)
  const assets = useRecoilValue(assetsState)

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const payloadItems = []

      Object.keys(colorVariants).forEach((key, index: number) => {
        const colorSetting = colorVariants[key]
        const sourceData = colorSetReplace(source, defaultColors, colorSetting.colors)

        payloadItems.push({
          ...INIT_VALUES,
          ...values,
          name: colorSetting.name + ' ' + values.name,
          source: sourceData,
          parent_colors: defaultColors, // Safe for future use
          modified_colors: colorSetting.colors,
          isParent: index === 0,
        })
      })

      const status = await uploadAssets(payloadItems)
    },
    [uploadAssets, defaultColors, source, colorVariants],
  )

  return (
    <div>
      <Formik
        key={'form-item'}
        initialValues={INIT_VALUES}
        validate={(values) => {
          const errors: FormErrors = {}
          return errors
        }}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <Field type="text" name="name" placeholder="Asset Name" />
            <Field type="number" name="maxItems" placeholder="Max items" />
            <Field type="textarea" name="tags" placeholder="Tags" as="textarea" />

            <label>Treat / Layer</label>
            <Field name="group" component="select">
              {assetGroups.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
            </Field>

            <label>Complementary Asset (NOT WORKING PROPERLY)</label>
            <Field name="complementary_asset" component="select">
              <option value={null}>
              -
              </option>
              {assets.map((item) => {

                const groupItem = assetGroups.find(searchItem => searchItem.id === item.group)

                return (
                  <option key={item.id} value={item.id}>
                    {item.name} ({groupItem.name})
                  </option>
                )
              })}
            </Field>
            <br />
            <br />
            <div>
              <button type="submit" className="danger" disabled={isSubmitting}>
                {isSubmitting ? 'Exporting..' : 'Upload'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AssetForm