import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { SpinnerCircularSplit } from 'spinners-react'

import CenterComponent from '../components/center-component/center-component'
import { useRecoilValue } from 'recoil'
import { collectionItemIdsState } from '../states/collection-item-ids.state'
import axios from 'axios'
import { getRequestConfig } from '../utils/get-request-config'
import { REACT_APP_REST_API_ENDPOINT } from '../env'

const LandingScreen = (): ReactElement => {
  const collectionCount = useRecoilValue(collectionItemIdsState)

  const [stats, setStats] = useState(null)

  const getStats = useCallback(() => {
    axios.get(REACT_APP_REST_API_ENDPOINT + '/stats', getRequestConfig()).then((result) => {
      setStats(result.data)
    })
  }, [])

  useEffect(() => {
    getStats()
  }, [getStats])

  if (!stats) {
    return (
      <CenterComponent>
        <SpinnerCircularSplit color={'white'} secondaryColor={'#d0a5b2'} size={300} thickness={5} />
      </CenterComponent>
    )
  }

  return (
    <CenterComponent>
      <h1>My Crypto Girl</h1>
      <div>In collection total: {collectionCount.length} girls</div>
      <div>Available Combination: {stats.combinations} pieces</div>
      <div>Creators: {stats?.creators?.length} Creators</div>
      Your shares appx. <strong>N/A</strong>
    </CenterComponent>
  )
}

export default React.memo(LandingScreen)