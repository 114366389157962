import React, { ReactElement } from 'react'
import { RecoilRoot } from 'recoil'
import './app.scss'
import IntlProvider from './intl-provider'
import 'react-flexbox-grid/dist/react-flexbox-grid.css'
import AppRouter from './app-router'

const App = (): ReactElement => {
  return (
    <RecoilRoot>
      <IntlProvider>
        <AppRouter />
      </IntlProvider>
    </RecoilRoot>
  )
}

export default App