import React, { ReactElement, useCallback, useState } from 'react'
import { useRandomizeItem } from '../database/use-random-item'
import {useRecoilState} from "recoil";
import {isGeneratingState} from "../states/is-generating-state";

interface Props {
  data?: any
}

const RandomizeButton = (props: Props): ReactElement => {
  const [isGenerating, setIsGenerating] = useRecoilState(isGeneratingState)
  const randomize = useRandomizeItem(props.data.id)

  const onRandomize = useCallback(async () => {
    setIsGenerating(true)
    const status = await randomize()

    if (status) {
      setIsGenerating(false)
      window.location.reload()
    }
  }, [randomize])

  return (
    <button type="submit" className="fun" disabled={isGenerating} onClick={onRandomize}>
      {isGenerating ? 'Randomizing..' : 'Random shit GO!'}
    </button>
  )
}

export default React.memo(RandomizeButton)