import { selectorFamily } from 'recoil'
import { AssetItem } from '../types/types'
import { assetsState } from './assets.state'
import {assetSearch} from "./asset-search";

export const itemAssetsIdsState = selectorFamily<string[], string>({
  key: 'itemAssetsIdsState',
  get:
    (groupId: string) =>
    ({ get }) => {
      /**
       * Get collection data
       */
      const searchString = get(assetSearch)
      const data = get(assetsState)
      let dataQuery = data.filter(searchItem => searchItem.group === groupId)

      if(searchString.length > 1){
        dataQuery = dataQuery
          .filter((searchItem: AssetItem) => {
            return (searchItem.name.toLowerCase().includes(searchString.toLowerCase()))
          })
      }

      return dataQuery.map((item) => item.id)

    },
})