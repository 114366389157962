import ColorPicker from '../color-picker/color-picker'
import React, { ReactElement, useCallback, useState } from 'react'
import { ColorCombination } from './color-replacement-set'

interface Props {
  color: string
  index: number
  onChange: (combination: ColorCombination, index: number) => void
  combination: any
  combinationIndex: number
  isLocked: boolean
}

const fixHexColor = (color: string) => {
  if (!color.startsWith('#')) {
    return '#' + color
  }

  return color
}

const ColorCombinationInput = (props: Props): ReactElement => {
  const { color, index, onChange, combination, combinationIndex, isLocked } = props

  const onColorChange = useCallback(
    (event) => {
      combination.colors[parseInt(event.target.id)] = fixHexColor(event.target.value)

      onChange(combination, combinationIndex)
    },
    [combinationIndex, onChange, combination],
  )

  const togglePicker = useCallback(() => {
    setPickerVisible((status) => !status)
  }, [])

  const [isPickerVisible, setPickerVisible] = useState(false)

  const onPickerChange = useCallback(
    (newColor) => {
      combination.colors[index] = newColor

      onChange(combination, combinationIndex)
    },
    [index, combination, combinationIndex, onChange],
  )

  return (
    <div className="" style={{ position: 'relative' }}>
      <div className="flex flex--align-items-center">
        <div
          style={{
            backgroundColor: color,
            padding: '0.5rem',
            width: '41px',
            height: '41px',
            marginBottom: '8px',
          }}
        />
        <input
          style={{ opacity: isLocked ? 0.5 : 1 }}
          disabled={isLocked}
          key={index}
          id={index.toString()}
          value={color}
          placeholder={`Color ${index + 1}`}
          onChange={onColorChange}
          onFocus={togglePicker}
        />
      </div>
      <ColorPicker
        isVisible={isPickerVisible}
        color={color}
        onChange={onPickerChange}
        onCloseRequested={togglePicker}
      />
    </div>
  )
}

export default ColorCombinationInput