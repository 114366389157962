import React, { ReactElement, useCallback, useState } from 'react'
import './editor-screen.scss'
import { Grid, Col, Row } from 'react-flexbox-grid'
import Dropzone from 'react-dropzone-uploader'
import ReUploadAssetForm from '../forms/re-upload-asset-form'
import ColorCombinations from '../components/asset-editor/color-combinations/color-combinations'

const AssetEditor = (): ReactElement => {
  //const [inputColors, setInputColors] = useState({ colors: emptyArray })
  const [parentColors, setParentColors] = useState([])

  const [sourceData, setSourceData] = useState(null)

  const readFile = useCallback((file) => {
    if (file) {
      let reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      reader.onload = function (evt) {
        const source = evt.target.result
        const fillsMatch = source?.toString().match(/#[a-fA-F0-9]{6}|#[a-fA-F0-9]{3}/g)

        const colors = fillsMatch.filter(function (item, pos) {
          return fillsMatch.indexOf(item) === pos
        })

        setParentColors(colors)
        setSourceData(source)
      }
      reader.onerror = function (evt) {
        console.log('Error reading file')
      }
    }
  }, [])

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(status, meta, file)
    console.log(readFile(file))
  }

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files) => {
    // no-op
  }

  return (
    <div className="asset-editor">
      <Grid>
        <Row>
          <Col lg={12}>
            <h1>Asset Editor</h1>
          </Col>
          <Col lg={12}>
            <ReUploadAssetForm source={sourceData} originColors={parentColors} />
          </Col>
          <Col lg={12}>
            <Dropzone
              onChangeStatus={handleChangeStatus}
              onSubmit={handleSubmit}
              multiple={false}
              inputContent={() => <div>Start by moving your SVG file here</div>}
              accept="image/svg"
            />
          </Col>
          <Col lg={12}>
            <ColorCombinations originColors={parentColors} source={sourceData} />
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default React.memo(AssetEditor)