import { AuthorId } from '../../../types/types'
import React, { ReactElement } from 'react'
import { useRecoilValue } from 'recoil'
import { author } from '../../../states/author'

interface Props {
  authorId: AuthorId
}

const GalleryItemAuthor = (props: Props): ReactElement => {
  const authorData = useRecoilValue(author(props.authorId))

  return (
    <div>
      by <strong>{authorData?.name}</strong>
    </div>
  )
}

export default GalleryItemAuthor