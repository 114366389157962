import { ChromePicker } from 'react-color'
import React, { ReactElement, useCallback, useEffect, useRef } from 'react'
import './color-picker.scss'

interface Props {
  isVisible: boolean
  color: string
  onChange: (color: string) => void
  onCloseRequested: () => void
  style?: any
}

function useOutsideClick(ref, isActive, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (isActive) {
          callback()
        }
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isActive, callback, ref])
}

const ColorPicker = (props: Props): ReactElement => {
  const { isVisible, onCloseRequested, color, onChange, style } = props

  const onChanged = useCallback(
    (inputValue) => {
      console.log(inputValue.hex)
      onChange(inputValue.hex)
    },
    [onChange],
  )

  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, isVisible, onCloseRequested)

  return (
    <div className="color-picker" onAuxClick={onCloseRequested} ref={wrapperRef} style={style}>
      {isVisible && (
        <div>
          <ChromePicker color={color} onChange={onChanged} />
        </div>
      )}
    </div>
  )
}

export default ColorPicker