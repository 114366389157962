import React, { ReactElement } from 'react'
import './color-combination-preview.scss'
import { colorSetReplace } from '../../../utils/color-set-replace'
import { ColorCombination } from '../color-replacement-set/color-replacement-set'
interface Props {
  combination: ColorCombination
  defaultColors: Array<string>
  sourceData: string
}

const ColorCombinationPreview = (props: Props): ReactElement => {
  const { defaultColors, combination, sourceData } = props

  if (!sourceData) {
    return null
  }

  const transformedData = colorSetReplace(sourceData, defaultColors, combination.colors)

  return (
    <div
      className="color-combination-preview"
      dangerouslySetInnerHTML={{ __html: transformedData }}
    />
  )
}

export default React.memo(ColorCombinationPreview)