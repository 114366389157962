import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { editedAssetIdState } from '../../../states/edited-asset-id.state'
import { itemAssetState } from '../../../states/item-asset.state'
import { getSourceColors } from '../../../utils/get-source-colors'
import ColorInput from '../../asset-editor/color-input/color-input'
import { referencedAssetIdState } from '../../../states/referenced-asset-id.state'
import { editedAssetState } from '../../../states/edited-asset.state'
import { colorSetReplace } from '../../../utils/color-set-replace'
import { addAssetItem } from '../../../database/add-asset-item'
import { updateAssetItem } from '../../../database/update-asset-item'
import { seriesState } from '../../../states/series.state'

const EmptyArray = []

/**
 * Inline Editor to editor colors of asset
 * @constructor
 */
const EditorAssetColors = (): ReactElement => {
  // Get selected asset
  const [editedAssetId, setEditedAssetId] = useRecoilState(editedAssetIdState)
  const referencedAssetId = useRecoilValue(referencedAssetIdState)
  console.log(editedAssetId)
  const editedAssetData = useRecoilValue(itemAssetState(editedAssetId))
  const refAsset = useRecoilValue(itemAssetState(referencedAssetId))
  const series = useRecoilValue(seriesState)
  const [editedAssetPayload, setEditAssetData] = useRecoilState(editedAssetState)
  const [newColors, setNewColors] = useState(EmptyArray)
  const [newName, setNewName] = useState(editedAssetData.name)
  const [newSeries, setNewSeries] = useState(editedAssetData.series)

  const onReferencePress = useCallback((color, index) => {
    // Copy Color
  }, [])

  const editedColors = getSourceColors(editedAssetData?.source)
  const refColors = getSourceColors(refAsset?.source)
  const colorHash = JSON.stringify(editedColors)

  const cancelAll = useCallback(() => {
    setEditedAssetId(null)
  }, [setEditedAssetId])

  useEffect(() => {
    setNewColors(editedColors)
    setEditAssetData(editedAssetData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorHash])

  const onEditColor = useCallback(
    (color, index) => {
      setNewColors((oldState) => {
        const newArray = [...oldState]
        newArray[index] = color
        // Update Recoil Edited data
        if (editedAssetData) {
          setEditAssetData({
            ...editedAssetData,
            source: colorSetReplace(editedAssetData.source, editedColors, newColors),
          })
        }

        return newArray
      })
    },
    [setEditAssetData, newColors, editedColors, editedAssetData],
  )

  /**
   * Save asset and update
   */
  const submitSave = useCallback(async () => {
    const status = updateAssetItem({
      ...editedAssetPayload,
      name: newName,
      series: newSeries,
      modified_colors: newColors,
    })

    if (status) {
      setEditedAssetId(null)
    }
  }, [newColors, newSeries, newName, setEditedAssetId, editedAssetPayload])

  const submitNew = useCallback(async () => {
    const status = addAssetItem({
      ...editedAssetPayload,
      name: newName,
      series: newSeries,
      parent: editedAssetId,
      modified_colors: newColors,
    })
    setEditedAssetId(null)
    console.log(status)
  }, [newColors, editedAssetId, newSeries, editedAssetPayload, setEditedAssetId, newName])

  /**
   * On name change
   */
  const onNameChange = useCallback((event) => {
    setNewName(event.target.value)
  }, [])

  /**
   * On series Change
   */
  const onSeriesChange = useCallback((event) => {
    const value = event.target.value
    const updateValue = value === '-' ? null : value
    setNewSeries(updateValue)
  }, [])

  if (!editedAssetId && !editedAssetData) {
    return null
  }

  // a
  return (
    <div
      style={{
        padding: '1rem',
        background: 'rgba(255, 255, 255, 0.3)',
        border: '1px solid rgba(255,255,255,0.2)',
      }}>
      <h4>Modify Colors</h4>
      <div style={{ display: 'flex' }}>
        <div>
          <strong>Original</strong>
          {editedColors.map((color, index) => {
            return (
              <ColorInput key={index.toString()} color={color} isEditable={false} index={index} />
            )
          })}
          <input
            name="originName"
            disabled
            value={editedAssetData.name}
            style={{ background: 'transparent' }}
          />
        </div>
        <div>
          <strong>New / Updated</strong>
          {newColors.map((color, index) => {
            return (
              <ColorInput
                key={index.toString()}
                color={color}
                index={index}
                onChange={onEditColor}
              />
            )
          })}
          <input name="colorName" onChange={onNameChange} value={newName} />
          <select name="series" onChange={onSeriesChange} value={newSeries}>
            <option value={null}>-</option>
            {series.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              )
            })}
          </select>
        </div>
      </div>
      <div>
        {!!refAsset && (
          <div>
            <strong>Reference Colors</strong>
            {refColors.map((color, index) => {
              return <ColorInput key={index.toString()} color={color} isEditable={false} />
            })}
          </div>
        )}
      </div>
      <div style={{ display: 'flex' }}>
        <button className="button danger" onClick={submitSave}>
          Save
        </button>
        <br />
        <button className="button neutral" onClick={cancelAll}>
          Cancel
        </button>
        <br />
        <button className="button fun" onClick={submitNew}>
          New Color Set
        </button>
      </div>
    </div>
  )
}

export default React.memo(EditorAssetColors)