import React, { ReactElement, Suspense } from 'react'

import { useRecoilValue } from 'recoil'
import { userState } from '../states/user-state'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import {AssetEditor, AssetsGalleryScreen, CollectionScreen, EditorScreen, LandingScreen, LoginScreen} from '../screens'
import Navigation from '../components/navigation/navigation'
import { DatastoreInit } from './datastore-init'

const AppRouter = (): ReactElement => {
  const user = useRecoilValue(userState)

  return (
    <Router>
      {user ? (
        <Suspense fallback={'Loading'}>
          <DatastoreInit />
          <Navigation />
          <Switch>
            <Route component={LandingScreen} path="/" exact={true} />
            <Route component={CollectionScreen} path="/collection" exact={true} />
            <Route component={EditorScreen} path="/editor/:itemId?" />
            <Route component={AssetsGalleryScreen} path="/assets" />
            <Route component={AssetEditor} path="/asset-editor/:itemId?" />
          </Switch>
        </Suspense>
      ) : (
        <Switch>
          <Route component={LoginScreen} path="/" exact />
        </Switch>
      )}
    </Router>
  )
}

export default AppRouter